import React from "react"
import Alert from "@material-ui/lab/Alert"
import styled from "styled-components"

const StyledAlert = styled(Alert)`
  .MuiAlert-message {
    width: 100%;
  }
`

const AlertWrong = () => {
  return (
    <StyledAlert variant="filled" severity="error" icon={false}>
      <h2>¡Código incorrecto!</h2>
      <p>
        ¡Ups! Parece que no has introducido la respuesta correcta. ¡No te
        desanimes! Si lo consideras, utiliza las pistas y sigue avanzando.
      </p>
    </StyledAlert>
  )
}

export default AlertWrong
