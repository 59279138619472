import React, { useState } from "react"
import AlertSuccess from "../alerts/alertSuccess"
import AlertWrong from "../alerts/alertWrong"
import styled from "styled-components"

import ContinueButton from "../buttons/continueButton"

import Input from "@material-ui/core/Input"
import FormControl from "@material-ui/core/FormControl"
import Container from "@material-ui/core/Container"
import Grid from "@material-ui/core/Grid"
import { theme } from "../layout/theme"

import PrimaryButton from "./../buttons/primaryButton"

const StyledFormControl = styled(FormControl)`
  display: block;
  width: 100%;
  margin-bottom: 2rem;
  margin-top: 1rem;

  button {
    margin-top: 5px;
  }

  input {
    border: 2px solid #ced4da;
    display: block;
    width: 100%;
    color: #6c757d;
    opacity: 1;
    padding-top: 0.5rem;
    padding-right: 1rem;
    text-align: center;
    padding-bottom: 0.5rem;
    padding-left: 1rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    margin-top: 5px;
    font-weight: 700;
    font-size: 1.4rem;

    &::placeholder {
      opacity: 1;
    }

    &:hover {
      border: 2px solid #ced4da;
      outline: none;
    }

    &:focus {
      border-color: ${theme.palette.primary.main};
    }
  }
`

const SolutionForm = props => {
  const [answer, setAnswer] = useState("")
  const [success, setSuccess] = useState("")

  const handleReset = () => {
    setSuccess("")
    setAnswer("")
  }

  const handleSubmit = (e, answer) => {
    e.preventDefault()

    if (
      answer.toLowerCase().replace(/\s/g, "") ===
      props.data.acf.solution.toLowerCase().replace(/\s/g, "")
    ) {
      setSuccess(true)
    } else {
      setSuccess(false)
    }
  }
  return (
    <Container>
      <Grid container justify="center">
        <Grid item lg={8} sm={12} xs={12}>
          <StyledFormControl>
            {success === true ? (
              <AlertSuccess data={props.data} />
            ) : success === false ? (
              <AlertWrong />
            ) : success === "" ? (
              ""
            ) : (
              ""
            )}

            {success === true ? (
              ""
            ) : success === "" ? (
              <Input
                type="text"
                disableUnderline={true}
                placeholder="Introduce el código..."
                onChange={event => setAnswer(event.target.value)}
              />
            ) : (
              <Input
                type="text"
                disableUnderline={true}
                placeholder="Introduce el código..."
                onChange={event => setAnswer(event.target.value)}
              />
            )}

            {success === true ? (
              <ContinueButton next={props.next} handleReset={handleReset} />
            ) : success === "" ? (
              <PrimaryButton
                type="submit"
                onClick={e => handleSubmit(e, answer)}
              >
                Comprobar
              </PrimaryButton>
            ) : (
              <PrimaryButton
                type="submit"
                onClick={e => handleSubmit(e, answer)}
              >
                Comprobar
              </PrimaryButton>
            )}
          </StyledFormControl>
        </Grid>
      </Grid>
    </Container>
  )
}

export default SolutionForm
