import React from "react"
import Button from "@material-ui/core/Button"
import styled from "styled-components"
import { theme } from "./../layout/theme"

const StyledButton = styled(Button)`
  border: 2px solid ${theme.palette.success.darker};
  text-align: center;
  width: 100%;
  display: block;
  color: white;
  box-shadow: none;
  font-weight: bold;
  background-color: ${theme.palette.success.darker};

  &:hover {
    font-weight: bold;
    box-shadow: none;
    color: white;
    opacity: 0.85;
    background-color: transparent;
  }
`

const ContinueButton = props => {
  // const url = urlToSlug(props.next)
  return (
    <StyledButton
      variant="contained"
      size="large"
      onClick={() => {
        props.next()
        if (props.handleReset) {
          props.handleReset()
        }
      }}
      className="mb-2"
    >
      Pulsa aquí para continuar
    </StyledButton>
  )
}

export default ContinueButton
