import styled from "styled-components"
import Button from "@material-ui/core/Button"

import { theme } from "./../layout/theme"

const PrimaryButton = styled(Button)`
  background-color: ${theme.palette.error.main};
  border-color: ${theme.palette.error.main};
  border: 1px solid ${theme.palette.error.main};
  font-weight: 700;
  width: 100%;
  display: block;
  color: white;
  font-size: 1.2rem;
  text-align:center;

  &:hover {
    background-color: #fff;
    color: ${theme.palette.error.main};
    border-color: ${theme.palette.error.main};
    border: 1px solid ${theme.palette.error.main};
    font-weight: 700;
  }
`

export default PrimaryButton
