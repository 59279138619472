import { useStaticQuery, graphql } from "gatsby"
export const useGamesData = () => {
  const { allWordpressWpJuegos } = useStaticQuery(
    graphql`
      {
        allWordpressWpJuegos {
          edges {
            node {
              title
              content
              featured_media {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1920, quality: 50) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
              acf {
                public_title
                challenges {
                  challenge {
                    wordpress_id
                    post_type
                  }
                }
              }
            }
          }
        }
      }
    `
  )
  return allWordpressWpJuegos.edges
}
