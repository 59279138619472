import React, { useState, useEffect } from "react"
import Layout from "./../components/layout/layout"
import CookiesModal from "./../components/alerts/cookiesModal"

import Container from "@material-ui/core/Container"
import Grid from "@material-ui/core/Grid"

import SEO from "../components/layout/seo"

import GamesData from "./../components/helpers/gamesData"
import ChallengesData from "./../components/helpers/challengesData"

import Challenge from "../templates/challenge"

import ModalProgress from "../components/alerts/modalProgress"

let index = 0

function encode(key, cycles) {
  for (let i = 0; i < cycles; i++) {
    key = btoa(key)
  }
  return key
}

function decode(key, cycles) {
  for (let i = 0; i < cycles; i++) {
    key = atob(key)
  }
  return key
}

const checkLocalStorage = (challengesData, setGameData) => {
  /* si hay progreso guardado */
  if (localStorage.getItem("progress")) {
    /* guardo el progreso */
    const decodedProgress = decode(localStorage.getItem("progress"), 10)
    const decodedIndex = decode(localStorage.getItem("index"), 10)

    let storedProgress = decodedProgress
    let storedIndex = decodedIndex

    /* filtrar id con array de challenges */
    let filter = challengesData.filter(function (challenge) {
      return challenge.node.wordpress_id === Number(storedProgress)
    })

    setGameData(filter[0])
    index = Number(storedIndex) + 1
  } else {
    /* no hay nada guardado en LocalStorage */
    return
  }
}

const IndexPage = () => {
  const gamesData = GamesData()
  const challengesData = ChallengesData()

  // const [choice, setChoice] = useState()
  const [modal, setModal] = useState(false)
  const [gameData, setGameData] = useState("")

  useEffect(() => {
    if (localStorage.getItem("progress") && localStorage.getItem("index")) {
      setModal(true)
    } else {
      challengeDone()
    }
  }, [challengesData])

  const handleModal = () => {
    checkLocalStorage(challengesData, setGameData)
  }

  const updateLocalStorage = (wordpress_id, index) => {
    const encoded_id = encode(wordpress_id, 10)
    const encoded_index = encode(index, 10)

    localStorage.setItem("progress", encoded_id)
    localStorage.setItem("index", encoded_index)
  }

  /* cuando se soluciona un challenge */
  const challengeDone = () => {
    /* si no es el último, guarda el wordpress_id del siguiente challenge */
    let wordpress_id =
      gamesData[0].node.acf.challenges[index].challenge.wordpress_id

    updateLocalStorage(wordpress_id, index)

    /* Filtra para encontrar el wordpress_id dentro del array de challenges */
    let next = challengesData.filter(function (challenge) {
      return challenge.node.wordpress_id === wordpress_id
    })
    setGameData(next[0])

    /* incremento al index para pasar al siguiente elemento 
      del array de challenges en gamesData */
    index += 1
  }

  return (
    <Layout>
      <SEO />
      <Container>
        {modal ? <ModalProgress handleModal={() => handleModal()} /> : ""}

        <Grid container justify="center">
          <Grid item lg={8} md={10} sm={11} xs={11}>
            {gameData === "" ? (
              ""
            ) : (
              <Challenge
                data={gameData}
                challengeDone={() => challengeDone(challengesData, setGameData)}
              />
            )}
          </Grid>
        </Grid>
      </Container>

      <CookiesModal />
    </Layout>
  )
}

export default IndexPage
