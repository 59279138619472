import { db } from "./firebase"
import SiteMetadata from "../components/helpers/siteMetadata"

//challenge index
const updateDb = async name => {
  // Setting index as completed in local storage
  const siteName = SiteMetadata().title

  let usedHints = 0
  let usedSolution = 0

  if (typeof window !== "undefined") {
    if (localStorage.getItem("usedHints")) {
      usedHints = localStorage.getItem("usedHints")
      localStorage.removeItem("usedHints")
    }

    if (localStorage.getItem("usedSolution")) {
      usedSolution = localStorage.getItem("usedSolution")
      localStorage.removeItem("usedSolution")
    }
  }

  let docRef = db.collection(siteName).doc(name)

  docRef
    .get()
    .then(function (doc) {
      if (doc.exists) {
        // console.log("It exists")
      } else {
        // doc.data() will be undefined in this case
        // console.log("No such document!")
        db.collection(siteName).doc(name).set({
          completado: 0,
          pistas: 0,
          soluciones: 0,
        })
      }
    })
    .catch(function (error) {
      console.log("Error getting document:", error)
    })

  const currentScore = await (
    await db.collection(siteName).doc(name).get()
  ).data()

  if (currentScore) {
    //Copying challenge object to new object
    let newScore = {}
    newScore["completado"] = currentScore["completado"] += 1

    if (usedHints > 0 || usedSolution > 0) {
      newScore["pistas"] = currentScore["pistas"] += Number(usedHints)
      newScore["soluciones"] = currentScore["soluciones"] += Number(
        usedSolution
      )

      //Replacing database object with new score
      await db.collection(siteName).doc(`${name}`).update({
        completado: newScore.completado,
        pistas: newScore.pistas,
        soluciones: newScore.soluciones,
      })
    } else if (usedHints > 0) {
      newScore["pistas"] = currentScore["pistas"] += Number(usedHints)

      //Replacing database object with new score
      await db.collection(siteName).doc(`${name}`).update({
        completado: newScore.completado,
        pistas: newScore.pistas,
      })
    } else if (usedSolution > 0) {
      newScore["soluciones"] = currentScore["soluciones"] += Number(
        usedSolution
      )

      //Replacing database object with new score
      await db.collection(siteName).doc(`${name}`).update({
        completado: newScore.completado,
        soluciones: newScore.soluciones,
      })
    } else {
      //Replacing database object with new score
      await db.collection(siteName).doc(`${name}`).update({
        completado: newScore.completado,
      })
    }
  }
}

export default updateDb
