import React from "react"
import Hint from "./hint"
import Container from "@material-ui/core/Container"
import Grid from "@material-ui/core/Grid"
import styled from "styled-components"

const StyledGrid = styled(Grid)`
  .hint {
    margin-top: 15px;
  }
`

const Hints = props => {
  const hints = props.hints
  return (
    <Container>
      <Grid container justify="center">
        <StyledGrid item lg={8}>
          {Object.keys(hints).map((keyName, i) => (
            <Hint hints={hints[i]} key={i} resetNum={props.resetNum} />
          ))}
        </StyledGrid>
      </Grid>
    </Container>
  )
}

export default Hints
