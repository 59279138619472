import React from "react"

import Alert from "@material-ui/lab/Alert"
import styled from "styled-components"
import updateDb from "../../utils/updateDb"

const StyledAlert = styled(Alert)`
  .MuiAlert-message {
    width: 100%;
    text-align: center;
    h2 {
      font-size: 1.5rem;
    }
    p {
      font-size: 1.2rem;
    }
  }
`

const AlertSuccess = props => {
  // Pasamos el title del challenge a updateDb, que actualiza firebase
  if (typeof window !== "undefined") {
    updateDb(props.data.title)
  }

  return (
    <StyledAlert variant="filled" severity="success" icon={false}>
      <h2>¡Correcto!</h2>
      <p>¡Buen trabajo! ¡Sigue adelante!</p>
    </StyledAlert>
  )
}

export default AlertSuccess
