import React from "react"
import FormControl from "@material-ui/core/FormControl"
import Container from "@material-ui/core/Container"
import styled from "styled-components"

import Grid from "@material-ui/core/Grid"

import PrimaryButton from "../buttons/primaryButton"

const StyledFormControl = styled(FormControl)`
  display: block;
  width: 100%;

  button {
    margin-top: 5px;
  }

  input {
    border: 1px solid #ced4da;
    display: block;
    width: 100%;
    color: #6c757d;
    opacity: 1;
    font-size: 1.2rem;
    padding-top: 0.5rem;
    padding-right: 1rem;
    padding-bottom: 0.5rem;
    padding-left: 1rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    margin-top: 5px;

    &:hover {
      border: 1px solid #ced4da;
      outline: none;
    }

    &:focus {
      border-color: #80bdff;
    }
  }
`

const OnlyText = props => {
  return (
    <Container>
      <Grid container justify="center">
        <Grid item lg={8}>
          <StyledFormControl>
            {props.data.acf.the_end === false ? (
              <PrimaryButton
                variant="contained"
                size="large"
                onClick={() => props.next()}
              >
                Continuar
              </PrimaryButton>
            ) : (
              ""
            )}
          </StyledFormControl>
        </Grid>
      </Grid>
    </Container>
  )
}

export default OnlyText
