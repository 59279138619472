import React, { useState, useEffect } from "react"

import Collapse from "@material-ui/core/Collapse"
import HintButton from "./../buttons/hintButton"
import SolutionButton from "./../buttons/solutionButton"

//Modal imports
import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogTitle from "@material-ui/core/DialogTitle"

let usedHints = 0
let usedSolution = 0

const Hint = props => {
  const hints = props.hints
  const [open, setOpen] = useState(false)
  const [modal, setModal] = useState(false)

  useEffect(() => {
    return () => {
      setOpen(false)
      usedHints = 0
      usedSolution = 0
    }
  }, [props.resetNum])

  const handleModal = x => {
    if (x === true) {
      if (typeof window !== "undefined") {
        //Comprobar si ya han visto las pistas / soluciones
        if (localStorage.getItem(hints.hint)) {
          setModal(false)
          setOpen(!open)
          return
          //Comprobar si no han visto las pistas / soluciones
        } else if (!localStorage.getItem(hints.hint)) {
          //Abrir modal de pistas / soluciones
          setOpen(!open)
          setModal(false)
          if (hints.solution === true) {
            usedSolution++
            localStorage.setItem(hints.hint, hints.hint)
            localStorage.setItem("usedSolution", usedSolution)
          } else {
            usedHints++
            localStorage.setItem(hints.hint, hints.hint)
            localStorage.setItem("usedHints", usedHints)
          }
        }
      }
    } else {
      setModal(false)
      return
    }
  }

  const handleClick = i => {
    if (typeof window !== "undefined") {
      if (localStorage.getItem(hints.hint)) {
        setOpen(!open)
      } else if (!localStorage.getItem(hints.hint)) {
        setModal(true)
      }
    }
  }
  return (
    <>
      {
        <Dialog
          open={modal}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          style={{ overflowY: "scroll" }}
        >
          <DialogTitle id="alert-dialog-title">¿Seguro?</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Al ver las pistas o la solución disminuyes considerablemente la
              dificultad del juego, y también parte de la diversión. ¿Quieres
              ver la {hints.solution === true ? "solución" : "pista"}?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" onClick={() => handleModal(true)}>
              Si
            </Button>
            <Button variant="outlined" onClick={() => handleModal(false)}>
              No
            </Button>
          </DialogActions>
        </Dialog>
      }
      {hints.solution === true ? (
        <div className="hint">
          <SolutionButton onClick={i => handleClick(i)} aria-expanded={open}>
            Solución
          </SolutionButton>
          <Collapse in={open === true ? true : false}>
            <div id="collapse-text">{hints.hint}</div>
          </Collapse>
        </div>
      ) : (
        <div className="hint">
          <HintButton onClick={i => handleClick(i)} aria-expanded={open}>
            Pista
          </HintButton>
          <Collapse in={open === true ? true : false}>
            <div id="example-collapse-text">
              <p>{hints.hint}</p>
            </div>
          </Collapse>
        </div>
      )}
    </>
  )
}

export default Hint
