import { useStaticQuery, graphql } from "gatsby"
export const useChallengesData = () => {
  const { allWordpressWpRetos } = useStaticQuery(
    graphql`
      {
        allWordpressWpRetos {
          edges {
            node {
              wordpress_id
              title
              slug
              id
              content
              featured_media {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1920, quality: 50) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
              acf {
                the_end
                donate_btn
                btn_text
                add_hints
                featured_img_top
                are_audio_files
                audio_files {
                  audio {
                    localFile {
                      publicURL
                    }
                  }
                }
                challenge_type
                is_next_step
                public_title
                solution
                hints {
                  hint
                  solution
                }
              }
            }
          }
        }
      }
    `
  )
  return allWordpressWpRetos.edges
}
